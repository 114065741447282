<template>
  <div class="grievance-card grievance-form">
    <h3 class="mb-24" id="tracking-form">नागरिक प्रतिक्रिया ट्रयाकिङ्ग</h3>
    <div class="flex">
      <div
        class="mb-24 field-wrapper"
        :class="{ 'form-errors': this.validationErrors['email'] }"
      >
        <label for="" class="mb-8">इमेल <span class="required">*</span></label>
        <input
          v-model="formData.email"
          type="text"
          placeholder="इमेल लेख्नुहोस्"
        />
        <span class="validation-error" v-if="this.validationErrors['email']">{{
          this.validationErrors["email"][0]
        }}</span>
      </div>
      <div
        class="mb-24 field-wrapper"
        :class="{
          'form-errors': this.validationErrors['application_id'],
        }"
      >
        <label for="" class="mb-8"
          >ट्रयाकिङ्ग नम्बर <span class="required">*</span></label
        >
        <input
          v-model="formData.application_id"
          type="text"
          placeholder="ट्रयाकिङ्ग नम्बर लेख्नुहोस्"
        />
        <span
          class="validation-error"
          v-if="this.validationErrors['application_id']"
          >{{ this.validationErrors["application_id"][0] }}</span
        >
      </div>
    </div>
    <div class="flex flex-btn mb-16">
      <button
        type="button"
        @click.prevent="postGrievance"
        :class="formsubmitting ? 'btn btn-primary active' : 'btn btn-primary'"
      >
        <span class="btn-loader" v-if="formsubmitting"></span>
        पठाउनुहोस्
      </button>
      <button
        type="button"
        class="btn btn-primary btn-primary--light"
        @click.prevent="resetFormData"
      >
        हटाउनुहोस्
      </button>
    </div>
    <p>* यो फिल्ड भर्न आवश्यक छ।</p>
  </div>
</template>

<script>
import GrievanceRepository from "../../repositories/GrievanceRepository";
export default {
  name: "grievanceForm",
  props: {
    type: { required: false, type: String, default: "" },
  },
  data() {
    return {
      formsubmitting: false,
      formData: {
        email: "",
        application_id: "",
      },
      validationErrors: {},
    };
  },
  methods: {
    async postGrievance() {
      this.formsubmitting = true;
      let { data } = await GrievanceRepository.postGrievanceStatusEmail(
        this.formData
      );
      this.formsubmitting = false;

      if (data.status == false && data.code == 422) {
        this.validationErrors = data.errors;
      }

      if (data.status == false && data.code == 0) {
        this.$toast.error(`Error while sending email`);
      }

      if (data.status == true) {
        this.$toast.success(data?.message || "Error while sending email");
        this.resetValidationErrors();
        this.resetFormData();
      }
    },
    resetFormData() {
      this.formData = {
        email: "",
        application_id: "",
      };
    },
    resetValidationErrors() {
      this.validationErrors = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.form-errors {
  label,
  .validation-error {
    background: -webkit-linear-gradient(#ff9d6c, #bb4e75);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  input,
  textarea {
    border: 1px solid #f55478;
  }
}
.required {
  color: #f55478;
}

.grievance-form {
  background: $neutrals-white;
  border-radius: 20px;
  padding: 80px 120px;
  position: relative;
  .flex {
    display: flex;
  }
  @media all and (max-width: 768px) {
    padding: 40px;
  }
  @media all and (max-width: 600px) {
    padding: 40px 20px;
    .flex {
      display: block;
    }
    .btn {
      width: 100%;
      & + .btn {
        margin-top: 16px;
      }
    }
  }
  .flex:not(.flex-btn) {
    justify-content: space-between;
    > .field-wrapper {
      width: 48%;
      @media all and (max-width: 600px) {
        width: 100%;
      }
    }
  }
  .flex-btn + p {
    background: -webkit-linear-gradient(#ff9d6c, #bb4e75);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Mukta-SemiBold";
  }
}

.field-wrapper {
  label {
    display: block;
    font-family: "Mukta-SemiBold";
  }
  input,
  textarea {
    border: 1px solid #c9ccd4;
    border-radius: 8px;
    height: 46px;
    padding: 10px 20px;
    width: 100%;
  }

  textarea {
    height: 150px;
  }
}

.btn-primary.active {
  opacity: 0.5;
}
</style>
